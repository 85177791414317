<template>
  <div v-if="!loading" class="collection mt-md-5 mt-3 mb-1">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div
          @click="$router.push(`/collections/${collection.slug}`)"
          v-for="(collection,index) in collections"
          class="px-sm-1 col-12 collection-col cursor-pointer"
          :class="$device == 'tab'? collections.length%2 == 1 && index == collections.length - 1? 'col-md-12' : 'col-md-6' : 'col-md-4'"
          :key="collection.id"
        >
          <!-- :style="{
            backgroundImage: `url(${
              !!collection.url
                ? collection.url
                : require('@/assets/collections/collection1.jpg')
            })`,
          }" -->
          <div
            class="collection-card"
            :style="{
              backgroundImage: `url('${collection.url}')`
            }"
          >
            <div class="price-box">
              <span>{{ collection.text }}</span>
              <h3>{{ collection.name }}</h3>
              <span>{{ collection.subtitle }}</span>
              <!-- <img :src="collection.url" /> -->
              <!-- <router-link :to="`/categories/${collection.slug}`" custom>
                                <button class="shop-btn">Shop Now</button>                            
                            </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCollections } from "../api/collection";

export default {
  data() {
    return {
      // collections: [],
      collections: [
        // {
        //   id: 1,
        //   image: require("@/assets/collections/collection1.jpg"),
        //   slug: "festive",
        //   name: "The Festive Collection",
        //   heading: "CELEBRATE WITH US",
        //   subheading: "For Weddings, Festivals & more",
        // },
        // {
        //   id: 2,
        //   slug: "ready-to-ship",
        //   image:
        //     "https://storage.googleapis.com/public_master/products/alaya-kaftan/PRI_9028%20L.jpg",
        //   name: "Ready To Ship",
        //   heading: "MOST POPULAR DESIGNS",
        //   subheading: "Get Dressed with Faster Shipping",
        // },
        // {
        //   id: 3,
        //   slug: "jewellery",
        //   image:
        //     "https://storage.googleapis.com/public_master/assets/jewellery.jpg",
        //   name: "The Jewellery Store",
        //   heading: "Accessorize Yourself",
        //   subheading: "For Any Occassion",
        // },
      ],
      // images: [
      //   require("@/assets/collections/collection1.jpg"),
      //   require("@/assets/collections/collection2.jpg"),
      //   require("@/assets/collections/collection3.jpg"),
      // ],
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  async created() {      
    // this.$emit("loaded")
    getCollections().then((res) => {
      this.collections = res
      // this.collections = res.data
      // this.collections = res.filter(item => item.isFeatured == true)
      this.$nextTick(() => this.$emit("loaded"));
    });
  },
};
</script>

<style scoped>
.collection {
  padding-left: 7%;
  padding-right: 7%;
}
.collection-card {
  position: relative;
  height: 650px;
  background: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}

.collection-col {
  padding-right: 6px;
  padding-left: 6px;
}

.collection-col:nth-child(3n) {
  padding-right: 0;
}

.collection-col:first-child {
  padding-left: 0;
}

.collection-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.158);
  position: absolute;
  top: 0;
}

.collection-card::before {
  content: "";
  width: 96%;
  height: 96%;
  border: 2px solid white;
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 1;
  /* right: 10px;
    bottom: 10px; */
}

.collection-card h3 {
  color: white;
  font-size: 26px;
}

.price-box {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  text-align: center;
  z-index: 1;
}

.price-box h3 {
  margin: 10px 0px;
  text-transform: uppercase;
  font-weight: normal;
}

.price-box span {
  color: white;
  font-size: 14px;
}

.price-box span:first-child {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 4px;
}

.shop-btn {
  border: 1px solid #626262;
  outline: none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: black;
  color: white;
  cursor: pointer;
}

@media (max-width: 1366px) {
  .collection-card {
    height: 480px;
  }
}

@media (max-width: 1024px) {
  .collection {
    padding-left: 2%;
    padding-right: 2%;
  }  
  
  .collection-card {
    height: 600px;
  }

  .collection-card h3 {
    font-size: 22px;
  }  
}

@media(max-width: 768px) {
  .collection-card {
    height: 500px;
  }
}

@media (max-width: 450px) {
  .collection-card {
    height: 460px;
  }

  .collection {
    padding-left: 0;
    padding-right: 0;
  }

  .collection-col {
    padding: 0;
  }
}


</style>
