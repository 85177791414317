import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import 'vue-material-design-icons/styles.css';
import VueMq from 'vue-mq'
import { capitalize, map } from 'lodash'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import PageLoader from './components/global/PageLoader.vue'

Vue.filter('titleCase', function (value) {
  if (!value) return ''
  // value = value.toString()
  return map(value.split(" "), capitalize).join(" ")
})

export function convertCurrency(value) {
  if (!value && value != 0) return null;
  let res = store.getters.currencies.find(currency => currency.name == store.getters.currency);
  return !!res ? Math.round(value * res.rate) : null;
}

export function formatCurrencyValue(value) {
  const convertedValue = convertCurrency(value);
  if (convertedValue === null) return '';
  let res = store.getters.currencies.find(currency => currency.name == store.getters.currency);
  return `${res.symbol}${convertedValue.toLocaleString()}/-`;
}
Vue.filter('ex', formatCurrencyValue)

Vue.prototype.$hidePageLoader = () => {
  store.commit("SET_LOADING_STATE", false)
}

Vue.use(VueGtag, {
  config: {
      id: 'G-2Y3WGK22CS',
      params: {
          send_page_view: false,
          pageTrackerEnabled: false
      }
  }
})
// }, router)

Vue.use(VueMeta)
Vue.use(VueMq, { breakpoints: { sm: 450, sm_md: 768, md: 1250, lg: Infinity }}) 
Vue.use(VueLazyload, {
  preLoad: 1.3,  
  observer: true,
  observerOptions:  {
    rootMargin: '0px',
    threshold: 0.1
  }
})
Vue.component('page-loader', PageLoader)

Vue.config.productionTip = false
AOS.init({
  once:true
});

let screenWidth = screen.width

if(screenWidth >= 768 && screenWidth <= 1024) {
  Vue.prototype.$device = 'tab'
}
else if(screenWidth > 1024) {
  Vue.prototype.$device = 'desktop'
}
else {
  Vue.prototype.$device = 'mobile'
}

router.beforeEach((to, from, next) => { 
  if(!to.matched.some(record=> record.meta.static )) {   
    store.commit("SET_LOADING_STATE", true)
  }  
  // store.commit("SET_PRODUCTS", [])
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

