<template>
  <div class="contact">
    <div class="container">
      <div class="row">
        <div class="col-12 my-md-3 text-center">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-12 ">
          <div class="contact-card">
            <h3 class="mb-3">ADDRESS</h3>
            <div class="contact-info">
              <div><strong>Harshita Jain Design Studio</strong></div>
              <p class="mt-2">Ground Floor, Prakriti Palms, Shaktigarh Path, Bhangagarh, G.S Road, Guwahati - 781005
                <br> Assam, India
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="contact-card">
            <h3 class="mb-3">CONTACT</h3>
            <div class="contact-info">
              <p>Tel : -91-6000646445, +91-8800448272</p>
              <p>Email: info.harshitajain@gmail.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-8 offset-lg-2 col-12  my-5">
          <h1 class="text-center">Write to Us</h1>
          <div class="contact-form-card">
            <form action="">
              <div class="row">
                <div class="col-md-6">
                  <input type="text" placeholder="Name">
                </div>
                <div class="col-md-6">
                  <input type="text" placeholder="Email">
                </div>
                <div class="col-md-12 mt-4">
                  <textarea name="" id="" cols="30" rows="10" placeholder="Enter your Message"></textarea>
                </div>
              </div>
              <button class="btn-default mt-2">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  metaInfo() {
    return {
      title: 'Contact',
      titleTemplate: '%s - Harshita Jain Design Studio',
      htmlAttrs: {
        lang: 'en',
        // amp: true
      }
    }
  },
  mounted() {
    this.$gtag.pageview(this.$route);              
  }
}
</script>


<style scoped>
.contact {
  min-height: 80vh;
}


.contact-card {
  border: 1px solid #D7CEC2;
  padding: 10px 20px;
  text-align: center;
  min-height: 210px;
  margin-bottom: 10px;
}

.contact-form-card {
  border: 1px solid #D7CEC2;
  padding: 30px 20px;
  text-align: center;
  min-height: 180px;
}

.contact-info {
  line-height: 25px;
}

.contact-form-card input,
.contact-form-card textarea {
  width: 100%;
  padding: 8px 10px;
  outline: 0;
  border: 1px solid #d7cec2;
  margin-top: 10px;
}

.btn-default {
  cursor: pointer;
}
</style>