<template>
  <div v-if="!loading" class="products-wrapper">
    <div class="container-fluid px-mobile">
      <div class="row">
        <div class="col-12 mb-3 mt-3">
          <Breadcrumb />
          <!-- <div class="breadcrumb">
            <span>HOME / </span> 
            <span>KURTA SETS</span>
          </div> -->
        </div>
        <div class="col-12">
          <div class="page-header text-center">
            <h1 class="m-0">{{ page_title | titleCase }}</h1>
            <!-- <span style="letter-spacing:2px;">{{ count }} products</span> -->
          </div>
        </div>
      </div>

      <!-- START SORT -->
      <div class="d-sm-block d-md-flex justify-content-between align-items-center mb-md-4" v-if="categoryUnavailable">
        <div class="d-sm-none d-none d-md-block">
          <div style="letter-spacing:2px; font-size: 12px; margin: 18px auto">
            RESULTS - {{ filteredProducts.length }} PRODUCTS
          </div>
        </div>

        <div class="row">
          <div class="col-6 px-1">
            <div>
              <input type="text" placeholder="SEARCH PRODUCTS" class="search-field search-bg" v-model="search" />
            </div>
          </div>
          <div class="col-6 px-1">
            <!-- <img src="@/assets/sort.png" alt=""> -->
            <div>
              <select name="sortPrice" id="sortPrice" @change="sortType(sortPrice)" v-model="sortPrice"
                class="search-filter">
                <option value="relevance" class="">
                  RELEVANCE
                </option>
                <option value="low">LOW TO HIGH</option>
                <option value="high">HIGH TO LOW</option>
                <!-- <option value="audi">Audi</option> -->
              </select>
            </div>
          </div>
          <div class="d-md-none" style="letter-spacing:2px; font-size: 12px; margin: 18px auto">
            {{ count }} products
          </div>
        </div>
      </div>

      <!-- END SORT -->

      <!-- PRODUCTS -->
      <div class="row" v-if="categoryUnavailable">
        <div class="col-6 col-md-6 col-lg-4 px-2 product-col" v-for="item in filteredProducts" :key="item.id">
          <!-- data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-delay="300" -->

                  
          <router-link :to="goToProduct(`/products/${item.slug}`)">
            <div class="product-card">
              <div class="product-image">
                <img v-lazy="item.images[0]" alt="" srcset="" class="lazy-img" />
              </div>
              <div class="product-info">
                <div class="name mt-2 mb-1">{{ item.name }}</div>
                <span class="price">{{ item.price[0] | ex }}</span>
              </div>
              <!-- 
            {{ item.price[0] }} -->
            </div>
          </router-link>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <ComingSoon />
        </div>
      </div>
      <!-- END PRODUCTS -->
    </div>
  </div>
</template>

<script>
  import SideCart from "@/components/SideCart";
  import Breadcrumb from "../components/Breadcrumb.vue";
  import { mapGetters } from "vuex";
  import ComingSoon from "../components/ComingSoon.vue";
  import { getSingleCollectionDetails } from './../api/collection'


  export default {
    data() {
      return {
        categoryUnavailable: false,
        page_title: "",
        category_slug: "",
        openpanel: false,
        sortDefault: "relevance",
        sortPrice: "relevance",
        search: "",
        collectionParams: null
      };
    },
    mounted() {
      this.$store.commit("SET_LOADING_STATE", true);
      let slug = this.$route.params.slug;

      // console.log(slug)

      let queryField;
      let value = slug;
      this.page_title = slug
        .trim()
        .split("-")
        .join(" ");

      if (this.$route.name == "Collection") {
        queryField = "collection_slugs";
      }

      if (this.$route.name == "Category") {
        queryField = "category_slugs";
      }

      // if (slug == "women") {
      //   queryField = "collection_slugs";
      //   value = "new-arrivals";
      // }


      // if slug is women then fetch all products or else fetch based on condition

      // e.g. - field can be 'collection_slugs' and value can be 'co-ord-set'

      this.$store
        .dispatch("fetchProducts", slug == "women" ? false : { field: queryField, value })
        .then(() => {
          if (!!this.products.length) {
            this.categoryUnavailable = true
            this.$store.commit("SET_LOADING_STATE", false);
          }
          this.$nextTick(async () => {
            this.filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
            let firstEl = this.filteredProducts.shift()
            this.filteredProducts.push(firstEl)
            this.$store.commit("SET_LOADING_STATE", false);

            let x = await getSingleCollectionDetails(slug)
            this.collectionParams = x.params

            this.$gtag.pageview({
                page_path: this.$route.path,
                page_title: this.page_title + ' | categories'
            });              
          });
        });

    },
    components: {
      SideCart,
      Breadcrumb,
      ComingSoon
    },
    methods: {
      sortType(data) {
        console.log(data);
        this.filteredProducts.sort(function (a, b) {
          if (data == "high") {
            return b.price[0] - a.price[0];
          } else if (data == "low") {
            return a.price[0] - b.price[0];
          } else if (data == "relevance") {
            return a.name.localeCompare(b.name);
          }
        });
      },
      goToProduct(url) {
        // console.log(Object.entries(this.collectionParams))
        // console.log(Object.values(this.collectionParams))
        // TODO: convert the params object into a query string that can be appended at the end of the url
        return !!this.collectionParams? url + '?filterAvailability='+this.collectionParams.filterAvailability : url
      }
    },

    computed: {
      filteredProducts() {
        if (!this.count) {
          return [];
        }
        return this.products.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      },

      ...mapGetters(["products", "loading"]),

      count() {
        return !!this.products.length ? this.products.length : 0;
        // return this.products.length;
      },
    },

  };
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  .products-wrapper {
    margin: 0;
    padding: 0 7%;
  }

  .px-mobile {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product-card {
    height: 750px;
    width: 100%;
    background: transparent;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .product-image {
    height: 684px;
    background: rgb(255, 245, 231);
    overflow: hidden;
  }

  .product-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .product-info .name {
    font-size: 20px;
    color: #56524d;
    font-weight: 400;
  }

  .product-info .price {
    color: #6b6761;
  }

  .page-header {
    margin-bottom: 40px;
  }

  .page-header h1 {
    font-weight: normal;
  }

  .page-header span {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px !important;
    margin-top: 10px;
    display: inline-block;
  }

  .search-field {
    position: relative;
    border: none;
    border: 1px solid #e8e8e1;
    outline: none;
    /* margin-bottom: 10px; */
    padding: 12px 8px;
    width: 100%;
    font-size: 10px;
    letter-spacing: 2px;
    /* padding-left: 22px; */
    z-index: 0;
  }

  .lazy-img[lazy="loaded"] {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-timing-function: ease-out;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* .search-field::after{
  position: relative;
  content: "";
  width: 20px;
  height: 20px;
  background: url('./../../assets/search-icon.png' );
  background-repeat: no-repeat;
  background-size: 16px auto;
	background-position: 2px center;
  left: 0;
  top: 0;
  z-index: 3;
  display: block;
}  */

  /* .search-bg{
  	background-image: url( './../../assets/search-icon.png' );
  background-repeat: no-repeat;
  background-size: 16px auto;
	background-position: 2px center;
}  */

  .search-field::placeholder {
    color: #969087;
  }

  .search-field:focus>.search-field::placeholder {
    padding-left: 0px !important;
  }

  .search-bg:focus {
    background-image: none;
  }

  .search-filter {
    width: 100%;
    padding: 12px 25px 10px 10px;
    /* margin-bottom: 10px; */
    border: 1px solid #e8e8e1;
    outline: none;
    color: #969087;
    /* background: white; */
    font-size: 10px;
    letter-spacing: 2px;
  }

  .breadcrumb {
    display: inline-block;
    /* padding-bottom: 6px; */
    padding-right: 10px;
    /* border-bottom: 1px solid #D7CEC2 ; */
  }

  .breadcrumb span {
    font-size: 10px;
    letter-spacing: 2px;
    color: #6d6767;
  }

  /* select {
	background-color: white;
	background-image: url( './../../assets/sort.png' );
	background-repeat: no-repeat;
	font-size: 24px;
	color: black;
  padding-right: 30px;
  background-size: 16px auto;
	background-position: 3px center;
 } */

  select>option {
    width: 100%;
  }

  .product-info>.name {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    /* width: 40px;   */
  }

  @media (max-width: 1366px) {
    .product-card {
      height: 590px;
      width: 100%;
      background: transparent;
      margin-bottom: 20px;
      overflow: hidden;
    }

    .product-image {
      height: 520px;
      background: rgb(255, 245, 231);
      overflow: hidden;
    }

    .product-image img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .product-info .name {
      font-size: 18px;
    }
  }

  @media (max-width: 1024px) {
    .product-card {
      height: 520px;
    }

    .product-image {
      height: 460px;
    }

    .product-image img {
      object-position: center;
    }
  }

  @media (max-width: 700px) {
    .page-header {
      margin-bottom: 20px;
    }

    .mt-8 {
      margin-top: 0px;
    }

    .products-wrapper {
      margin: 0;
      padding: 0;
    }

    .px-mobile {
      padding-left: 20px;
      padding-right: 20px;
    }

    .product-col:nth-child(odd) {
      padding-right: 6px !important;
    }

    .product-col:nth-child(EVEN) {
      padding-left: 6px !important;
    }


    h1 {
      font-weight: normal;
      font-size: 28px;
    }

    .product-card {
      height: 330px;
      width: 100%;
      background: transparent;
      margin-bottom: 15px;
      overflow: hidden;
    }

    .product-image {
      height: 270px;
      background: rgb(255, 245, 231);
      overflow: hidden;
    }

    .product-image img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .product-info .name {
      font-size: 14px;
    }

    .product-info .price {
      font-size: 12px;
    }
  }
</style>