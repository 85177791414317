import { render, staticRenderFns } from "./TermAndCondition.vue?vue&type=template&id=3f87abef&scoped=true"
var script = {}
import style0 from "./TermAndCondition.vue?vue&type=style&index=0&id=3f87abef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f87abef",
  null
  
)

export default component.exports