var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"collection mt-4 mb-3",attrs:{"id":"categories"}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.collections),function(collection){return _c('div',{key:collection.id,staticClass:"col-md-4 col-6 collection-col",on:{"click":function($event){return _vm.goToCategory(collection.slug)}}},[_c('div',{staticClass:"collection-card",style:({
            backgroundImage: `url(${
              !!collection.image
                ? collection.image
                : require('@/assets/collections/collection1.jpg')
            })`,
          })},[_c('div',{staticClass:"price-box"},[_c('h3',[_vm._v(_vm._s(collection.name))])])])])}),0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h2',[_vm._v("CATEGORIES")])])])
}]

export { render, staticRenderFns }