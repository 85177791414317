  <template>
    <div
      class="col-md-5 px-0 order-1 order-md-2"
      
    >
      <div class="shop-cart text-center py-3" v-if="cart.length == 0">
        Cart is Empty

        <router-link to="/" class="d-block mt-5">
          <u>Return to Shopping</u>
        </router-link>
      </div>

      <div class="shop-cart py-3" v-else>
        <div
          class="
            d-md-none
            mt-0 mt-md-4
            order-summary
            d-flex
            justify-content-between
            align-items-center
          "
          style="padding-left: 20px; padding-right: 20px"
        >
          <div @click="toggleOrderSummary($event)">
            <span style="font-size: 14px; padding-bottom: 7px">
              {{ orderSummaryText }}
              <span>
                <Component :is="showOrder ? 'ChevronUp' : 'ChevronDown'" />
              </span>
            </span>
          </div>

          <span>
            {{
              hasDeliveryCharge ? grandTotal : (cartTotal - discountAmount) | ex
            }}
            <!-- <ChevronDown /> -->
          </span>
        </div>

        <div
          v-if="!discount.applied && showOrder == false"
          class="d-md-none text-center"
          style="padding-top: 5px"
        >
          <span
            class="cursor-pointer"
            @click="focusCoupon"
            style="
              font-size: 12px;
              letter-spacing: 2px;
              color: #5d574f;
              font-weight: bold;
            "
            >Have discount coupon?</span
          >
        </div>

        <div class="item-cart-list" v-show="$mq != 'sm' || showOrder">
          <div
            class="item-card"
            v-for="(item, index) in cart"
            :key="item.id + '-' + index"
          >
            <div class="item-image">
              <img :src="item.images[0]" alt="" />
            </div>
            <div
              class="item-price d-flex align-items-center justify-content-between"
            >
              <div class="pl-1">
                {{ item.name }}
                <div class="item-detail mt-1">
                  <span>Size: {{ item.size }}</span> <br />
                  <span>Qty: {{ item.quantity }}</span>
                </div>
              </div>
              <div>{{ item.price | ex }}</div>
            </div>
          </div>
          <hr class="line" />
          <div class="d-flex justify-content-between subtotal">
            <div>Subtotal:</div>
            <div>
              {{ cartTotal | ex }}
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2 shipping">
            <div>Shipping:</div>
            <div v-if="hasDeliveryCharge">
              <span v-if="deliveryCharge == 0"> Free Delivery </span>
              <span v-else>
                {{ deliveryCharge | ex }}
              </span>
            </div>
            <div v-else>Calculated in the next step</div>
          </div>
          <div
            v-if="discount.applied"
            class="d-flex justify-content-between mt-2 shipping"
          >
            <div>
              Discount (<span
                class="cursor-pointer"
                style="
                  font-size: 12px;
                  letter-spacing: 2px;
                  color: #5d574f;
                  font-weight: bold;
                "
                @click="$store.commit('SET_DISCOUNT', null)"
              >
                REMOVE </span
              >)
            </div>
            <div>-{{ discountAmount | ex }} ({{ discount.rate }}% OFF)</div>
          </div>
          <div v-else class="d-flex justify-content-between mt-3 shipping">
            <div style="flex: 4">
              <input
                ref="couponRef"
                id="couponInput"
                autofocus
                class="coupon-input"
                style="width: 100%"
                v-model="couponCode"
                placeholder="Enter coupon code here"
                type="text"
       
              />
            </div>
            <button
              style="flex: 2"
              type="button"
              @click="applyCoupon"
              class="btn-default"
            >
              APPLY
            </button>
          </div>
          <hr class="line" />
          <div class="d-flex justify-content-between align-items-center mt-2 total">
            <div>
              Total: <br />
              <div class="mt-1" v-if="hasDeliveryCharge" style="font-size: 12px">
                (Inclusive of all taxes)
              </div>
            </div>
            <!-- <div v-if="isCouponApplied">{{ grandTotal }}</div>
                  <div v-else>  {{ cartTotal | ex }} </div> -->
            <div>
              <span
                class="font-strikethrough"
                style="font-size: 14px"
                v-if="discount.applied"
                >{{
                  hasDeliveryCharge ? cartTotal + deliveryCharge : cartTotal | ex
                }}</span
              >
              {{
                hasDeliveryCharge ? grandTotal : (cartTotal - discountAmount) | ex
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>


<script>
import { mapGetters } from "vuex";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import { getCoupon } from "../api/coupons";

export default {
  props: {
    hasDeliveryCharge: {
      type: Boolean,
    },
  },
  data() {
    return {
      errors: [],
      showOrder: false,
      coupon: null,
      couponCode: "",
      personInfo: {
        firstName: null,
        lastName: "",
        address: "",
        optionalAddress: "",
        city: "",
        country: "",
        state: "",
        pinCode: "",
        phoneNumber: "",
      },
    };
  },
  created() {
    let personInfo = this.$store.getters.personInfo;
    if (!!personInfo) {
      this.personInfo = Object.assign({}, personInfo);
    }
  },
  computed: {
    ...mapGetters([
      "cart",
      "cartTotal",
      "grandTotal",
      "discount",
      "discountAmount",
      "deliveryCharge",
    ]),
    orderSummaryText() {
      return this.showOrder ? "Hide Order Summary" : "Show Order Summary";
    },
    totalLabel() {
      return this.hasDeliveryCharge ? "" : "";
    },
  },
  methods: {
    saveAddress() {
      this.errors = [];
      if (this.formValidation() && this.cart.length) {
        console.log("✅ Form validation OK");
        this.$store.dispatch("personInfo", this.personInfo);
        this.$router.push("/shipping");
      } else {
        console.log("❌ FORM VALIDATION ERROR");
      }
    },
    async applyCoupon() {
      try {
        const discount = await getCoupon(this.couponCode);
        this.$store.commit("SET_DISCOUNT", { ...discount, applied: true });
        // console.log(discount)
        // if(this.cartTotal < discount.minAmount) {
        //   console.log("please add at least " + discount.minAmount)
        //   return
        // }
        // const discountAmount = this.cartTotal * (discount.rate * 0.01)
        // this.discountProp.appliedDiscount = discountAmount >= discount.maxDiscount ? discount.maxDiscount : discountAmount
        // this.grandTotal =  discountAmount >= discount.maxDiscount? this.cartTotal - discount.maxDiscount : this.cartTotal - discountAmount
      } catch (error) {
        console.log(error);
      }
    },
    focusCoupon() {
      this.$nextTick(() => {
        this.toggleOrderSummary()
        console.log(this.$refs.couponRef);
        this.$refs.couponRef.focus();
      });
    },
    toggleOrderSummary(e) {
      // console.log(e.target.id == "couponInput")
      this.showOrder = !this.showOrder;
    },

    // FORM VALIDATION
    formValidation() {
      if (!this.cart.length) {
        this.errors.push("Your Cart is Empty");
      }

      if (!this.personInfo.firstName || !this.personInfo.lastName) {
        this.errors.push("Name Required");
      }

      if (!this.personInfo.email) {
        this.errors.push("Email Required");
      } else if (!this.validEmail(this.personInfo.email)) {
        this.errors.push("Valid email required.");
      }

      if (!this.personInfo.address) {
        this.errors.push("address required");
      }
      if (!this.personInfo.city) {
        this.errors.push("city required");
      }

      if (!this.personInfo.phoneNumber) {
        this.errors.push("phone number required");
      }

      if (!this.errors.length) {
        return true;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    // END FORM VALIDATION
  },
  components: {
    ChevronDown,
    ChevronUp,
  },
};
</script>
    
    <style scoped>
.address {
  display: flex;
  min-height: 100vh;
}
.shop-cart {
  /* background: rgb(241, 241, 241); */
  background-color: #f1ede9;
  /* height: 100vh; */
  height: 100%;
}

.address h1 {
  font-size: 30px;
  font-weight: normal;
}

.pl-100 {
  padding-left: 100px;
}

.field-input {
  border: 1px #eee solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  word-break: normal;
  line-height: inherit;
}

.address-wrapper {
  margin-top: 0px;
  margin-bottom: 20px;
}

.item-cart-list {
  padding: 30px 100px 20px 30px;
}

.item-card {
  height: 100px;
  /* background: rgb(248, 246, 246); */
  display: flex;
  margin-top: 10px;
  border: 1px solid #ccc;
}

.item-image,
.item-price {
  display: inline-block;
}

.item-image {
  height: 100%;
  width: 120px;
  background: #ccc;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.line {
  margin: 20px 0px;
  height: 1px;
  border: 0;
  background-color: #ccc;
}

.item-price {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.steps {
  margin-top: 20px;
  font-size: 10px;
  text-transform: uppercase;
}

.active {
  font-weight: bold;
}

.shipping,
.subtotal {
  font-size: 14px;
}

.total {
  font-weight: bold;
  font-size: 20px;
  font-weight: normal;
}

.item-detail {
  font-size: 11px;
}

.order-summary {
  margin-top: 10px;
}

.coupon-input {
  border: 1px solid #ccc;
  padding: 10px;
}

.coupon-input:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 767px) {
  .pl-100 {
    padding-left: 15px;
  }

  .btn-outline {
    /* margin-top: 10px; */
  }

  .item-cart-list {
    padding: 10px 20px;
  }

  .hide-summary {
    display: none;
  }

  .show-summary {
    display: block !important;
  }
}
</style>