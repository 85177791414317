<template>
  <div v-if="!loading" class="product-page mt-8">
    <div class="container-fluid" style="margin-top: -4px">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <!-- <div class="main-image">
            <img :src="currentProduct.img[previewIndex]" alt="" srcset="" />
        </div> -->

          <ProductDisplay :images="currentProduct.images" v-if="!!currentProduct.images" />
          <!-- <div class="preview-wrapper">
            <div :class="[ index == previewIndex ? 'active' : '' ]" href="" role="button" class="preview" v-for="(preview,index) in currentProduct.img" :key="preview +'-'+index" @click="changePreview(index)">
              <img :src="preview" alt="">
            </div>
          </div> -->


        </div>
        <div class="col-md-12 col-lg-6 mt-4">
          <Breadcrumb />
          <div class="product-main-wrapper">
            <!-- <h1>{{ currentProduct.name }}</h1> -->
            <h1>{{ currentProduct.name }}</h1>
            <div class="mb-4">
              <!-- {{ currentProduct.price[currentSizeIndex] | ex }}  -->
              {{ currentProduct.price[0] | ex }}
              <span v-if="
                !!currentProduct.actualPrice &&
                currentProduct.actualPrice > currentProduct.price[0]
              ">
                <span class="discount-price"><strike> {{ currentProduct.actualPrice }} </strike></span>
                <span class="discount"> 30% OFF</span>
              </span>
            </div>
            <hr v-if="!currentProduct.isCompact" />
            <div v-if="!currentProduct.isCompact">
              <div class="
                  d-flex
                  align-items-center
                  justify-content-between
                  mt-3
                  mb-2
                ">
                <span>Size:</span>
                <span class="cursor-pointer" style="
                    font-size: 12px;
                    letter-spacing: 2px;
                    color: #5d574f;
                    font-weight: bold;
                  " @click="chartModal = !chartModal">VIEW SIZE CHART</span>
              </div>

              <!-- {{ currentProduct.details[3].value.size }} -->


              <ul class="m-0 p-0">
                <li class="cursor-pointer mt-2" v-for="(item, index) in sizeAvailabilityList"
                  :key="item.size + '-' + index">
                  <div class="size-card"
                    :class="[!!productParams.filterAvailability && item.availability !== productParams.filterAvailability ? 'grey' : index == currentSizeIndex ? 'active' : '']"
                    @click="!!productParams.filterAvailability && item.availability !== productParams.filterAvailability ? undefined : sizeProperty(item, index)">
                    {{ item.size }}
                  </div>
                </li>
                <li>
                  <div class="size-card custom-size-card cursor-pointer mt-2" :class="[
                    currentSizeIndex == currentProduct.sizes.length
                      ? 'active'
                      : '',
                  ]" @click="
                    handleCustomSize('CUSTOM', currentProduct.sizes.length)
                    ">
                    Custom
                  </div>
                </li>
              </ul>
            </div>

            <div class="mt-3 pt-2" v-show="currentSize == 'CUSTOM'">
              <div class="mb-2" style="font-size: 14px; line-height: 22px">
                CUSTOM MEASUREMENT IN INCHES <br />
                (BUST,WAIST,HIP,SHOULDER)
              </div>
              <input type="text" class="custom-field" placeholder="E.g. - 10, 10, 10, 10" v-model="customSizeOption" />
            </div>
            <div class="mt-3 pt-2" v-if="!!productComponents.length">
              <div class="mb-2" style="font-size: 14px; line-height: 22px">
                BUY ONLY PARTS:
              </div>
              <multiselect v-model="selectedParts" :options="productComponents" :multiple="true" :close-on-select="true"
                :clear-on-select="true" :searchable="false" :preserve-search="false" placeholder="Pick your choices"
                label="title" track-by="title" :hide-selected="true" :show-labels="false" :preselect-first="false">
                <template slot="tags" slot-scope="{ values }"><span class="multiselect__single">
                    <span v-for="value in values" :key="value.name" class="mr-3">
                      {{ value.title }}
                    </span>
                  </span>
                </template>
              </multiselect>
              <!-- {{ value }} -->
            </div>
            <div v-if="!currentProduct.isCompact" class="mt-3 pt-2">
              <div class="mb-2" style="font-size: 14px; line-height: 22px">
                INSTRUCTIONS (OPTIONAL):
              </div>
              <input type="text" class="custom-field" placeholder="E.g. - Make the sleeves longer"
                v-model="additionalInstructions" />
              <div class="mt-2">
                <small>
                  <strong>Note:</strong> For additional customizations, you can
                  directly talk to us via Whastapp/Call</small>
              </div>
            </div>
            <template v-if="currentProduct.isInStock">
              <button class="btn-default btn-outline mt-3 mb-2" @click="addToCart()">
                ADD TO CART
              </button>
              <button class="btn-default mb-1" @click="buyNow()">
                BUY IT NOW
              </button>
            </template>
            <p class="mt-4" v-else>
              <big><strong>Out of Stock</strong></big>
            </p>
            <div class="product-text-description mt-4">
              <p v-html="currentProduct.description"></p>
              <p v-if="!currentProduct.isCompact" style="margin-top: 30px">Color: {{ currentProduct.color }}</p>
              <p v-if="!currentProduct.isCompact">Fabric: {{ currentProduct.details[0].value }}</p>
              <!-- <p>Availability: {{ currentProduct.details[3].value }}</p> -->
              <p v-if="!currentProduct.isCompact">Outfit Full Length: {{ currentProduct.outfitFullLength }}</p>
              <p v-if="!currentProduct.isCompact">Instructions: {{ currentProduct.details[2].value }}</p>
              <p>Availability: {{ currentAvailability }}</p>
              <p v-if="!currentProduct.isCompact">Delivery Time: {{ currentProduct.details[1].value }}</p>
              <p>
                Disclaimer: Actual color may slightly vary due to photographic
                lighting sources or your display settings
              </p>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-6" style="padding-right: 6px">
              <div class="ask-a-question">
                <button class="btn-default btn-outline mt-3 mb-2" @click="askQuestion()">
                  ASK A QUESTION
                </button>
              </div>
            </div>
            <div class="col-6" style="padding-left: 6px">
              <a href="https://wa.me/+916000646445" target="_blank">
                <button class="btn-default btn-outline mt-3 mb-2">
                  CHAT WITH US
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- RELATED ITEMS -->
      <div class="row">
        <div class="col-12">
          <RelatedItems @remount="$emit('remount')" />
        </div>
      </div>

      <div style="font-size: 14px; cursor: pointer" class="text-center mb-4" @click="$router.back()">
        <!-- <router-link custom> -->
        <!-- <ArrowLeftTop /> &nbsp;  BACK TO ALL ALL KURTA SETS -->
        <ArrowLeftTop /> &nbsp; GO BACK
        <!-- </router-link> -->
      </div>
    </div>

    <!-- VIEW CHART MODAL -->
    <modal :bgColor="'#E5E5DB'" v-if="chartModal" @closeChart="closeChart">
      <template v-slot:header>
        <span></span>
      </template>
      <template v-slot:body>
        <div class="row">
          <!-- <div class="col-12 col-md-6">
            
          </div> -->
          <div class="col-12 my-3">
            <img src="https://storage.googleapis.com/public_master/assets/size_chart_latest.jpg" style="width: 100%"
              alt="" />
          </div>
        </div>
      </template>
    </modal>

    <!-- ASK A QUESTION MODAL -->

    <modal v-if="questionModal" @closeChart="closeChart">
      <template v-slot:header>
        <h2>ASK A QUESTION</h2>
      </template>
      <template v-slot:body>
        <div class="row">
          <!-- <div class="col-12 col-md-6">
            
          </div> -->
          <div class="col-12">
            <form action="">
              <label for="name">NAME</label>
              <input type="text" class="question-input" />
              <label for="name">EMAIL</label>
              <input type="text" class="question-input" />
              <label for="name">PHONE NUMBER</label>
              <input type="text" class="question-input" />
              <label for="name">MESSAGE</label>
              <textarea name="" id="" cols="30" rows="10"></textarea>
              <button class="btn-default mt-4 mb-2">SUBMIT</button>
            </form>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RelatedItems from "@/components/RelatedItems";
import ArrowLeftTop from "vue-material-design-icons/ArrowLeft.vue";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import ProductDisplay from "../../components/ProductDisplay.vue";
import Modal from "./../../components/Modal.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Multiselect from "vue-multiselect";

export default {


  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "Product Image Preview") {
        vm.fromPreview = true;
      }
    });
  },
  data() {
    return {

      currentSize: "M",
      currentSizeIndex: 0,
      quantity: 1,
      additionalInstructions: "",
      previewIndex: 0,
      chartModal: false,
      questionModal: false,
      showCustomSize: false,
      customSizeOption: "",
      fromPreview: false,
      selectedParts: [],
      partsAvailable: [
        { name: "Hat", id: 455, price: 5000 },
        { name: "Shirt", id: 234, price: 1000 },
        { name: "Trousers", id: 241, price: 4000 },
      ],
      currentAvailability: '',
      productParams: {}
    };
  },
  components: {
    RelatedItems,
    ArrowLeftTop,
    ProductDisplay,
    Breadcrumb,
    Whatsapp,
    Multiselect,
    modal: Modal,
  },
  computed: {
    ...mapGetters(["currentProduct", "loading"]),
    sizeAvailabilityList() {
      if (!this.currentProduct) return []
      return typeof this.currentProduct.details[3].value == 'string' ? this.defaultSizeAvailabilityList(this.currentProduct.availability) : this.currentProduct.details[3].value
    },
    productComponents() {
      return !this.currentProduct.id || !this.currentProduct.components
        ? []
        : this.currentProduct.components.map((item) => ({
          ...item,
          title: `${item.name} - Rs ${item.price}`,
        }));
    },
  },
  metaInfo() {
    return {
      title: this.currentProduct.name,
      titleTemplate: '%s - Harshita Jain Design Studio',
      htmlAttrs: {
        lang: 'en',
        // amp: true
      }
    }
  },
  mounted() {
    
    // this.$gtag.pageview(this.$route)

    this.productParams = this.$route.query
    // TODO: filter using findIndex

    if (this.fromPreview) {
      this.$hidePageLoader();
      this.$store.commit("SET_DISABLE_LOADING", false);
    } else {
      let product_slug = this.$route.params.slug;

      this.$store
        .dispatch("fetchProduct", product_slug)
        .then(() => {
          this.currentAvailability = typeof this.currentProduct.details[3].value == 'string' ? this.currentProduct.details[3].value : this.currentProduct.details[3].value[0].availability
          if (!this.currentProduct.id) {
            this.$router.replace("/");
          } else {
            if (this.currentProduct.category == "Accessories") {
              this.currentSize = "FREE SIZE";
            }
            this.$nextTick(() => {
              this.$hidePageLoader()

              this.$gtag.pageview({
                page_path: this.$route.path,
                page_title: `${this.currentProduct.name} | ${this.currentProduct.category[0]}`
              });              

              // document.title = "Product Page - Harshita Jain"

              // console.log("page view sent")
          });
          }
        })
        .catch((e) => {
          console.log("no");
        });
    }
  },
  methods: {
    closeChart(val) {
      this.chartModal = val;
      this.questionModal = val;
    },
    changePreview(index) {
      this.previewIndex = index;
    },
    sizeProperty(data, index) {
      if (typeof this.currentProduct.details[3].value !== 'string') {
        this.currentAvailability = data.availability
      }
      this.currentSize = data.size;
      this.currentSizeIndex = index;
    },
    defaultSizeAvailabilityList(availability) {
      return [
        { size: 'XS', availability },
        { size: 'S', availability },
        { size: 'M', availability },
        { size: 'L', availability },
        { size: 'XL', availability },
        { size: 'XXL', availability },
        { size: 'XXXL', availability },
      ]
    },
    addToCart() {
      // console.log(this.isitemPresent());
      // return
      if (this.isitemPresent()) {
        var x = this.$store.getters.cart.findIndex(
          (product) =>
            product.id == this.currentProduct.id &&
            product.size == this.currentSize
        );
        this.$store.dispatch("updateCart", x);
        this.$store.commit("ACTIVE_CART", true);
      } else {
        // let cartItem = Object.assign({}, this.currentProduct);
        // let x = {
        //   ...cartItem,
        //   size: this.currentSize == 'CUSTOM'? this.currentSize+` (${this.customSizeOption})` : this.currentSize,
        //   quantity: this.quantity,
        //   price: this.currentProduct.price[this.currentSizeIndex]
        // }

        let cartItem = Object.assign({}, this.currentProduct);

        if (!!this.selectedParts.length) {
          let str = this.selectedParts.map((item) => item.name).join(",");
          cartItem.name = cartItem.name + ` (${str})`;
          cartItem.id =
            cartItem.id + this.selectedParts.map((item) => item.id).join("_");
          cartItem.price = this.selectedParts
            .map((item) => item.price)
            .reduce((a, b) => a + b, 0);
        } else {
          cartItem.price = this.currentProduct.price[0];
        }

        this.$store
          .dispatch("addToCart", {
            ...cartItem,
            size:
              this.currentSize == "CUSTOM"
                ? this.currentSize + ` (${this.customSizeOption})`
                : this.currentSize,
            quantity: this.quantity,
            // price: this.currentProduct.price[0],
            additionalInstructions: this.additionalInstructions,
          })
          .then(() => {
            this.$store.commit("ACTIVE_CART", true);
          });
      }
    },

    buyNow() {
      let cartItem = Object.assign({}, this.currentProduct);
      this.$store
        .dispatch("addToCart", {
          ...cartItem,
          size:
            this.currentSize == "CUSTOM"
              ? this.currentSize + ` (${this.customSizeOption})`
              : this.currentSize,
          quantity: this.quantity,
          price: this.currentProduct.price[this.currentSizeIndex],
          additionalInstructions: this.additionalInstructions,
        })
        .then(() => {
          this.$router.push("/address");
        });
    },

    askQuestion() {
      this.questionModal = !this.questionModal;
    },

    isitemPresent() {
      var index = this.$store.getters.cart.findIndex(
        (product) =>
          product.id == this.currentProduct.id &&
          product.size == this.currentSize
      );

      return index !== -1 ? true : false;
    },

    handleCustomSize(size, data) {
      // this.showCustomSize = !this.showCustomSize
      this.currentSizeIndex = data;
      this.currentSize = size;
      this.currentAvailability = "Made to Order"
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.product-page {
  margin: 0;
  padding: 0 7%;
  padding-top: 2%;
}

h1 {
  font-weight: normal;
  font-size: 2.2rem;
  margin-bottom: 10px;
}

h2 {
  font-weight: normal;
  font-size: 18px;
}

.main-image img {
  width: 100%;
}

.product-main-wrapper {
  margin-top: 10px;
}

.size-card {
  height: 40px;
  min-width: 40px;
  padding: 0 5px;
  /* width: 140px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e1;
  font-size: 12px;
}

.custom-size-card {
  padding: 0px 10px;
  text-transform: uppercase;
}

.active {
  border: 1px solid #5d574f !important;
}

li {
  list-style: none;
  display: inline-block;
}

li:not(:last-child) {
  margin-right: 10px;
}

.btn-outline {
  border: 1px solid #d7cec2;
  background-color: white;
  color: #6b6761;
}

.btn-size-cart {
  letter-spacing: 2px;
}

.custom-field {
  width: 100%;
  padding: 9px 10px;
  outline: none;
  border: 1px solid #e8e8e1;
  height: 40px;
}

.custom-field:focus {
  border: 1px solid black;
  outline: none;
}

.product-description {}

.preview-wrapper {
  display: flex;
}

.preview {
  flex: 1;
  height: 150px;
  overflow: hidden;
  background-color: #eee;
  border: 1px solid transparent;
  /* margin: 0px 2px; */
}

.preview:not(:first-child):not(:last-child) {
  margin: 0px 4px;
  /* border: 2px solid red; */
}

.preview img {
  width: 100%;
  object-fit: contain;
  object-position: top center;
}

.custom-field::placeholder {
  font-style: italic !important;
}

.discount-price {
  color: rgb(184, 184, 184);
}

.discount {
  background: rgb(3, 143, 3);
  color: white;
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 14px;
}

label {
  font-size: 11px;
  letter-spacing: 2px;
}

.question-input {
  width: 100%;
  padding: 8px;
  outline: none;
  border: 1px solid #eee;
  margin: 10px 0px;
  background-color: #f1ede9;
}

textarea {
  outline: none;
  border: 1px solid #eee;
  width: 100%;
  margin-top: 10px;
  background-color: #f1ede9;
}

.grey {
  background-color: rgb(245, 237, 237);
  color: rgba(128, 123, 123, 0.232);
  cursor: default;
}

@media (max-width: 1400px) {
  .product-page {
    margin: 0;
    padding: 0 0.5%;
    padding-top: 2%;
  }

  h1 {
    font-weight: normal;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
  }

  .main-image img {
    width: 100%;
  }

  .product-main-wrapper {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .product-main-wrapper h1 {
    font-size: 20px;
  }

  .product-page {
    margin: 0;
    padding: 0;
  }
}
</style>