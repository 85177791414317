import {collectionsRef} from '../services/database'

// fetch all available collections
export function getCollections() {
  let res = []
  return collectionsRef.where("active", "==", true).get()
    .then(snaps => {
      snaps.forEach(snap => res.push(snap.data()))
      return res
    })
}

// fetch a single collection(
export async function getSingleCollectionDetails(slug){
  let collectionSnap = await collectionsRef.where("slug", "==", slug).get()
  return !!collectionSnap.docs.length? collectionSnap.docs[0].data() : {}
}