import { productsRef, db } from './../services/database'
import store from './../store'

export const listProducts = (condition) => {
    return new Promise((resolve, reject) => {        
        let ref = productsRef.where('isLive','==', true)
        if(!!condition) {
            ref = ref.where(`${condition.field}`, "array-contains", `${condition.value}`)
        }
        let docs = []
        ref
        .get()
        .then(snaps => {
            let data;            
            snaps.forEach(async snap => {
                data = snap.data()                                                        
                docs.push(data)
            })
            return resolve(docs)
        })
        .catch(e => reject(e)) 
    })
}


export function fetchProductsList() {
    console.log("fetching products...")
    let products = []

    return new Promise((resolve, reject) => {
        productsRef
            // .limit(50)
            .onSnapshot(snaps => {
                products = []
                snaps.forEach(snap => {
                    products.push(snap.data())
                })
                console.log("products fetched!")
                store.commit("SET_PRODUCTS_LIST", products.map(item => ({ ...item, createdAt: !item.createdAt ? !!item.timestamp ? item.timestamp : 0 : item.createdAt })).sort((a, b) => b.createdAt - a.createdAt))
                // store.commit("SET_PRODUCTS_LOADED", true)
                resolve(products)
            })
    })

}

export async function getProduct(slug) {

    let ref = productsRef.where("slug", "==", slug)    
    try {
        let snap = await ref.get()
        return Promise.resolve(snap.empty? {} : snap.docs[0].data())
    } catch (e) {
        return Promise.reject(e)
    }
  }
  

export async function getRelatedItems () {
    // return []
    
    let currentItem = store.getters.currentProduct
    let relatedItems = []

    if(!!currentItem.relatedItems && !!currentItem.relatedItems.length) {
        for(let item of currentItem.relatedItems) {
            let snap = await db.doc("Products/"+item).get()
            relatedItems.push(snap.data())
        }
        return relatedItems.filter(item => item.isLive == true)
    }

    let category = currentItem.category[0]
    let categoryRef = productsRef.where("category", "array-contains", category).orderBy("startingPrice", "asc")
    let categorySnaps = await categoryRef.get()
    let categorySnapItems = [] 
    categorySnaps.forEach(snap => categorySnapItems.push(snap.data()))    
    let numItems = categorySnapItems.length
    let itemIndex = categorySnapItems.findIndex(item => item.startingPrice == currentItem.startingPrice)            
    if(numItems <= 5) {
        return categorySnapItems.filter(item => item.id !== currentItem.id)
    }

    if(itemIndex > 1) {
        if ((numItems - itemIndex) > 2) {
            relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex-2], categorySnapItems[itemIndex+1], categorySnapItems[itemIndex+2]]
        }
        else if ((numItems - itemIndex) > 1) {
            relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex-2], categorySnapItems[itemIndex+1], categorySnapItems[itemIndex-3]]
        }
        else {
            relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex-2], categorySnapItems[itemIndex-4], categorySnapItems[itemIndex-3]]
        }
    } 
    else if (itemIndex > 0) {
        relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex+2], categorySnapItems[itemIndex+1], categorySnapItems[itemIndex+3]]
    }    
    else {
        relatedItems = [categorySnapItems[itemIndex+1], categorySnapItems[itemIndex+2], categorySnapItems[itemIndex+4], categorySnapItems[itemIndex+3]]
    }

    return Promise.resolve(relatedItems)

    // if(itemIndex > 1 && (numItems - itemIndex) > 2) {
    //     relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex-2], categorySnapItems[itemIndex+1], categorySnapItems[itemIndex+2]]
    // } else {
    //     relatedItems = [categorySnapItems[itemIndex-1], categorySnapItems[itemIndex-2], categorySnapItems[itemIndex-4], categorySnapItems[itemIndex-3]]
    // }
}


// export const getInfluencerProfiles = (influencersList) => {
//     return new Promise((resolve, reject) => {                        
//         let profileUrlList = influencersList.map(user => user.platform.url.split('?')[0])    
//         axios.get('http://localhost:9000/scrape-users', {
//             params: {
//                 profileUrlListStr: profileUrlList.join('|')
//             }
//         })
//         .then(res => {
//             let data = []
//             res.data.userDataList.forEach((item,index) => {
//                 data.push({...getInfluencerData(JSON.parse(item)), uid: influencersList[index].id, updatedAt: Date.now() })
//             })            
//             // data.push({...getInfluencerData(json), uid: influencersList[0].id })            
//             resolve(data)
//         })
//         .catch(e => reject(e))
//     })
// }

// export const getInfluencerData = (json) => {
//     let user = json.graphql.user
//     let userPart = _.pick(user, [
//             'biography', 
//             'edge_follow',
//             'edge_followed_by',
//             'edge_owner_to_timeline_media',
//             'category_name',            
//             'is_private',
//             'full_name'
//         ])

//         let node;
//         userPart.posts = userPart.edge_owner_to_timeline_media.edges.map(edge => { 
//         node = edge.node
//         return {
//             id: node.id,
//             url: node.display_url,
//             commentsCount: node.edge_media_to_comment.count,
//             // location: node.location.name,
//             timestamp: node.taken_at_timestamp,
//             likeCount: node.edge_media_preview_like.count,
//             isVideo: node.is_video
//             }        
//         })
//         userPart['followersCount'] = userPart.edge_followed_by.count
//         userPart['followingCount'] = userPart.edge_follow.count
//         userPart['postsCount'] = userPart.edge_owner_to_timeline_media.count,        
//         delete userPart.edge_owner_to_timeline_media
//         delete userPart.edge_followed_by
//         delete userPart.edge_follow
//         return userPart    
// }

// export const updateInfluencer = (data, operation) => {
//     if(!data.id) { return }      
  
//     switch(operation) {
//       case 'arrayUnion':
//         value = firestore.FieldValue.arrayUnion(value)
//         break
//       case 'arrayRemove':
//         value = firestore.FieldValue.arrayRemove(value)               
//     }
        
//     return influencersRef
//       .doc(data.id)
//       .update({ [data.field]: data.val })      
//   }
