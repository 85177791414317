import { render, staticRenderFns } from "./HomeCategory.vue?vue&type=template&id=1a91d2b8&scoped=true"
import script from "./HomeCategory.vue?vue&type=script&lang=js"
export * from "./HomeCategory.vue?vue&type=script&lang=js"
import style0 from "./HomeCategory.vue?vue&type=style&index=0&id=1a91d2b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a91d2b8",
  null
  
)

export default component.exports