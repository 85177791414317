<template>
  <div class="products-wrapper">
    <div class="container-fluid px-mobile">
      <div class="row">
        <div class="col-12 mb-3 mt-3">
          <Breadcrumb />
          <!-- <div class="breadcrumb">
            <span>HOME / </span> 
            <span>KURTA SETS</span>
          </div> -->
        </div>
        <div class="col-12">
          <div class="page-header text-center">
            <h1 class="m-0">Kurta Sets</h1>
            <!-- <span style="letter-spacing:2px;">{{ count }} products</span> -->
          </div>
        </div>
      </div>

      <!-- START SORT -->
      <div class="d-sm-block d-md-flex justify-content-between align-items-center mb-md-4">

     

      <div class="d-sm-none d-none d-md-block">
          <div style="letter-spacing:2px; font-size: 12px; margin: 18px auto">
             RESULTS - {{ count }} PRODUCTS
            </div>
      </div>


      <div class="row">
            <div class="col-6 px-1">
              <div>
                <input
                  type="text"
                  placeholder="SEARCH PRODUCTS"
                  class="search-field search-bg"
                  v-model="search"
                />
              </div>
            </div>
            <div class="col-6 px-1">
              <!-- <img src="@/assets/sort.png" alt=""> -->
              <div>
                <select
                  name="sortPrice"
                  id="sortPrice"
                  @change="sortType(sortPrice)"
                  v-model="sortPrice"
                  class="search-filter"
                >
                  <option value="relevance" class="" v-model="sortDefault">
                    RELEVANCE
                  </option>
                  <option value="low">LOW TO HIGH</option>
                  <option value="high">HIGH TO LOW</option>
                  <!-- <option value="audi">Audi</option> -->
                </select>
              </div>
            </div>
             <div class="d-md-none" style="letter-spacing:2px; font-size: 12px; margin: 18px auto">
              {{ count }} products
            </div>
          </div>

           </div>

          <!-- END SORT -->
        
        
      

          <!-- PRODUCTS -->
          <div class="row">
            <div
              class="col-6 col-md-4 px-2"
              v-for="item in filteredProducts"
              :key="item.id"
            >
              <router-link :to="`/products/${item.slug}`">
                <div
                  class="product-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-delay="300"
                >
                <div class="product-image">
                  <img v-lazy="item.images[0]" alt="" srcset="" />
                </div>
                <div class="product-info">
                  <div class="name mt-2 mb-1">{{ item.name }}</div>
                  <span class="price">Rs.  {{ item.price[0] }}</span>
                </div>
                  <!-- 
            {{ item.price[0] }} -->
                </div>
              </router-link>
            </div>
          </div>
          <!-- END PRODUCTS -->
        
      
    </div>
  </div>
</template>

<script>
import SideCart from "@/components/SideCart";
import Breadcrumb from "../../components/Breadcrumb.vue";
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      openpanel: false,
      products: null,
      count: 0,
      sortDefault: "relevance",
      sortPrice: "relevance",
      search: "",
    };
  },

  created() {
    let slug = this.$route.params.slug
    let category_slug = slug.replace('-',' ')
   
    this.products = this.$store.getters.categoryProducts(category_slug);
    this.count = this.products.length;
  },
  components: {
    SideCart,
    Breadcrumb,
  },
  methods: {
    // openCart() {
    //   console.log("open-cart");
    //   this.openpanel = !this.openpanel;
    //   console.log(this.openpanel);
    // },
    setAOSDelay(index) {
      let delay = 500;
      if (index < 4) {
        return delay * (index + 1);
      } else {
        return delay * (index - 3);
      }
    },
    setAOSOffset(index) {
      let offset = 100;
      if (!index % 2) {
        offset = 200;
      }
      return offset;
    },
    sortType(data) {
      console.log(data);
      this.filteredProducts.sort(function(a, b) {
        if (data == "high") {
          return b.price[0] - a.price[0];
        } else if (data == "low") {
          return a.price[0] - b.price[0];
        } else if (data == "relevance") {
          return a.id - b.id;
        }
      });
    },
  },

  computed: {
    filteredProducts() {
      if (!this.count) {
        return [];
      }
      return this.products.filter((item) =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );
      // console.log(x)
    },
  },
  metaInfo() {
    return {
      title: 'Products',
      titleTemplate: '%s - Harshita Jain Design Studio!',
      htmlAttrs: {
        lang: 'en',
        // amp: true
      }
    }
  },
};
</script>

<style scoped>

a{
  text-decoration: none;
}


.products-wrapper{
  margin: 0;
  padding: 0 7%;  
}

.px-mobile {
  padding-left: 15px;
  padding-right: 15px;
}
.product-card {
  height: 750px;
  width: 100%;
  background: transparent;
  margin-bottom: 20px;
  overflow: hidden;
}

.product-image{
  height: 684px;
  background: rgb(255, 245, 231);
  overflow: hidden;
}

.product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-info .name{
  font-size: 20px;
  color: #56524d;  
  font-weight: 400;
}

.product-info .price{
  color: #6b6761;
}

.page-header {
  margin-bottom: 40px;
}

.page-header h1{
  font-weight: normal;
}

.page-header span {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px !important;
  margin-top: 10px;
  display: inline-block;
}

.search-field {
  position: relative;
  border: none;
  border: 1px solid #e8e8e1;
  outline: none;
  /* margin-bottom: 10px; */
  padding: 12px 25px 12px 10px;
  width: 100%;
  font-size: 10px;
  letter-spacing: 2px;
  /* padding-left: 22px; */
  z-index: 0;  
}

/* .search-field::after{
  position: relative;
  content: "";
  width: 20px;
  height: 20px;
  background: url('./../../assets/search-icon.png' );
  background-repeat: no-repeat;
  background-size: 16px auto;
	background-position: 2px center;
  left: 0;
  top: 0;
  z-index: 3;
  display: block;
}  */

/* .search-bg{
  	background-image: url( './../../assets/search-icon.png' );
  background-repeat: no-repeat;
  background-size: 16px auto;
	background-position: 2px center;
}  */

.search-field::placeholder{
  color: #969087;
}

.search-field:focus > .search-field::placeholder {
  padding-left: 0px !important;
}

.search-bg:focus {
  background-image: none;
}

.search-filter {
  width: 100%;
  padding: 12px 25px 10px 10px;
  /* margin-bottom: 10px; */
  border: 1px solid #e8e8e1;
  outline: none;
  color: #969087;
  /* background: white; */
  font-size: 10px;
  letter-spacing: 2px;
}

.breadcrumb {
  display: inline-block;
  /* padding-bottom: 6px; */
  padding-right: 10px;
  /* border-bottom: 1px solid #D7CEC2 ; */
}

.breadcrumb span {
  font-size: 10px;
  letter-spacing: 2px;
  color: #6d6767;
}

/* select {
	background-color: white;
	background-image: url( './../../assets/sort.png' );
	background-repeat: no-repeat;
	font-size: 24px;
	color: black;
  padding-right: 30px;
  background-size: 16px auto;
	background-position: 3px center;
 } */

select > option {
  width: 100%;
}



@media(max-width:1366px){
.product-card {
  height: 590px;
  width: 100%;
  background: transparent;
  margin-bottom: 20px;
  overflow: hidden;
}

.product-image{
  height: 520px;
  background: rgb(255, 245, 231);
  overflow: hidden;
}

.product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-info .name{
  font-size: 18px;
}
}


@media (max-width: 768px) {

  .page-header {
    margin-bottom: 20px;
  }
  .mt-8 {
    margin-top: 0px;
  }

  .products-wrapper{
    margin: 0;
    padding:0;
  }

  .px-mobile {
    padding-left: 30px;
    padding-right: 30px;
  }

  h1 {
    font-weight: normal;
    font-size: 28px;
  }

  .product-card {
    height: 280px;
    width: 100%;
    background: transparent;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .product-image{
  height: 220px;
  background: rgb(255, 245, 231);
  overflow: hidden;
}

  .product-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .product-info .name{
    font-size: 16px;    
  }

  .product-info .price{  
    font-size: 14px;
  }
}

</style>
